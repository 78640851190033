'use client'
import React, { FC, useCallback } from 'react'
import Image from 'next/image'

import Link from '~/src/components/generic/Link'
import EnvelopeIcon from '~/src/components/generic/PhosphorIcons/EnvelopeIcon'

import LoginButton from '~/src/components/LoginPage/Button'
import { getURLWithQueryParams } from '~/src/components/LoginPage/utils'

const LoginPage: FC = () => {
  const handleOnSignInWithGoogleClick = useCallback(() => {
    location.href = getURLWithQueryParams(process.env.NEXT_PUBLIC_GOOGLE_LOGIN_URL)
  }, [])

  const handleOnSignInWithOktaClick = useCallback(() => {
    location.href = getURLWithQueryParams(process.env.NEXT_PUBLIC_OKTA_LOGIN_URL)
  }, [])

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-black">
      <div className="absolute h-full w-full animate-[fadein_1s_ease-in-out_0s_1_forwards] bg-login bg-cover"></div>
      <div className="z-10 flex h-[354px] w-[511px] flex-col rounded-lg bg-white">
        <div className="flex w-full flex-col items-center justify-center border-b pb-6 pt-11">
          <Image src="/images/ryderLogo.png" alt="Ryder Logo" width={160} height={44} />
        </div>
        <div className="flex flex-col items-center justify-end space-y-6 px-24 pt-6">
          <div className="text-xl font-bold">Sign in to Radius</div>
          <LoginButton
            className="w-full bg-blue-500 text-white"
            onClick={handleOnSignInWithOktaClick}
          >
            <div className="flex items-center space-x-2">
              <div>Sign in with </div>
              <Image src="/icons/okta.svg" alt="Okta Icon" width={44} height={14} />
            </div>
          </LoginButton>
          <LoginButton
            className="w-full bg-gray-800 text-white"
            onClick={handleOnSignInWithGoogleClick}
          >
            <div className="flex items-center space-x-2">
              <div>Sign in with Google </div>
              <Image src="/icons/google.svg" alt="Google Icon" width={20} height={20} />
            </div>
          </LoginButton>
        </div>
      </div>
      <div className="absolute bottom-8 z-10 text-white">
        <EnvelopeIcon className="inline" />
        <Link href="mailto:engineering@baton.io" className="ml-2">
          engineering@baton.io
        </Link>
      </div>
    </div>
  )
}

export default LoginPage
