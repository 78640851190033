import React, {
  CSSProperties,
  FC,
  PropsWithChildren as PWC,
  useEffect,
  useRef,
} from 'react'
import { twMerge } from 'tailwind-merge'

interface AnimationProps {
  duration?: number
  delay?: number
  iterationCount?: CSSProperties['animationIterationCount']
}
interface FadeTransitionProps {
  animation?: AnimationProps
  className?: string
  contents: Array<string>
  index: number
}

const useAnimationStyles = ({
  duration = 1,
  delay = 1,
  iterationCount = 1,
}: AnimationProps = {}): CSSProperties => {
  return {
    animationDuration: `${duration}s`,
    animationDelay: `${delay}s`,
    animationIterationCount: iterationCount,
    animationFillMode: 'forwards',
  }
}

const FadeTransition: FC<PWC<FadeTransitionProps>> = ({
  animation = {
    duration: 1,
    delay: 0,
    iterationCount: 1,
  },
  contents,
  index,
  className,
}) => {
  const prevIndex = useRef<number>(0)

  useEffect(() => {
    prevIndex.current = index
  }, [index])

  const animationStyles = useAnimationStyles(animation)

  return (
    <div className={twMerge('relative', className)}>
      {prevIndex.current === index ? (
        <div className={'w-full'}>{contents[index]}</div>
      ) : (
        <>
          <div className={'animate w-full animate-[fadein]'} style={animationStyles}>
            {contents[index]}
          </div>
          <div
            className="animate absolute top-0 w-full animate-[fadeout]"
            style={animationStyles}
          >
            {contents[prevIndex.current]}
          </div>
        </>
      )}
    </div>
  )
}
export default FadeTransition
