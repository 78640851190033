import React, { FC, PropsWithChildren as PWC } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from '~/src/components/generic/Button'

const LoginButton: FC<
  PWC<{
    className: string
    disabled?: boolean
    onClick: React.MouseEventHandler<HTMLButtonElement>
  }>
> = ({ className, children, disabled, onClick }) => {
  return (
    <Button
      className={twMerge(
        'flex h-10 flex-row items-center justify-center gap-2 rounded py-2 px-4 text-center align-middle',
        !disabled && 'active:shadow-inner4xl hover:brightness-90',
        disabled && 'cursor-none',
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default LoginButton
