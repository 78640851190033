import React, { FC, PropsWithChildren as PWC } from 'react'
import { twMerge } from 'tailwind-merge'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'

type LinkProps = FC<PWC<{ className?: string } & NextLinkProps>>

const Link: LinkProps = ({ href, className, children }) => {
  return (
    <NextLink href={href} legacyBehavior>
      <a className={twMerge('inline-block text-link underline', className)}>
        {children}
      </a>
    </NextLink>
  )
}

export default Link
